
html {
    height: 100%;
}
body {
    min-height: 100%;
}
.navbar-custom-text-rtl {
    display: inline-block;
    padding-top: -0.5rem;
    padding-bottom: -0.5rem;
}
.no-li-style-rtl{
    list-style-type: none;
}

/* The container */
.container-rtl {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default checkbox */
.container-rtl input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmark-rtl {
    position: absolute;
    top: 15px;
    left: 980px;
    height: 25px;
    width: 25px;
    background-color: #cccccc;
}
/* On mouse-over, add a grey background color */
.container-rtl:hover input ~ .checkmark-rtl {
    background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.container-rtl input:checked ~ .checkmark-rtl {
    background-color: #2196F3;
}
/* Show the checkmark when checked */
.container-rtl input:checked ~ .checkmark-rtl:after {
    display: block;
}

.cursor{
    cursor: pointer
  }


  .zoom {
    transition: transform .2s; /* Animation */
    margin: 0 auto;
  }
  
  .zoom:hover {
    transform: scale(1.5);
  }


