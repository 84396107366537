html {
  height: 100%;

}
body {
  min-height: 100%;
}
.navbar-custom-text {
  display: inline-block;
  padding-top: -0.5rem;
  padding-bottom: -0.5rem;
}
.no-li-style{
  list-style-type: none;
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-span {
  display: block;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.radio-container .inpRadio {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 70px;
  width: 70px;
  background-color:#ccc;
  border-radius:5px;
}
/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color:aqua;
}
/* When the checkbox is checked, add a blue background */


.radio-container .inpRadio:checked ~ .checkmark {
  background-color: #2196F3;
}
/* Show the checkmark when checked */
.radio-container .inpRadio:checked ~ .checkmark:after {
  display: block;
}

.cursor{
  cursor: pointer
}

/* .modal-backdrop{
    display: none;
  } */

  .q-img{
    border: 1px solid #ddd;
    width: 500px;
    height: 300px;
    object-fit: contain;
    margin-right: 20px;
  }

  /* img:hover {
    box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  } */


  .zoom {
    transition: transform .2s; /* Animation */
    margin: 0 auto;
  }
  
  .zoom:hover {
    transform: scale(1.5);
  }



  .video-container {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .video-container video {
    width: 100%;
    height: 93%;
    object-fit: cover;

  }

